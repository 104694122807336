//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Reference component: https://github.com/Devuelopers/vue-player/blob/main/src/components/VideoPlayer.vue

import { mapGetters } from 'vuex'
import CoVideoPlayerTranscription from '~/components/common/CoVideoPlayerTranscription'

export default {
    name: 'CoVideoPlayer',
    components: {
        CoVideoPlayerTranscription
    },
    props: {
        videoUrl: {
            type: String,
            required: true
        },
        subtitlesSrc: {
            type: String,
            default: ''
        },
        videoType: {
            type: String,
            default: ''
        },
        poster: {
            type: String,
            default: ''
        },
        nativePoster: {
            type: String,
            default: ''
        },
        hasControls: {
            type: Boolean,
            default: true
        },
        showDuration: {
            type: Boolean,
            default: false
        },
        showPlayIcon: {
            type: Boolean,
            default: true
        },
        isPlayFromStartAfterClick: {
            type: Boolean,
            default: false
        },
        showYtPlayIcon: {
            type: Boolean,
            default: false
        },
        allowEnableSound: {
            type: [Boolean, Number],
            default: false
        },
        transcription: {
            type: String,
            default: ''
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        },
        constantAspectRatioClass: {
            type: String,
            default: 'm-16-9'
        },
        aspectRatio: {
            type: Number,
            default: null
        },
        disableAutoPause: {
            type: Boolean,
            default: false
        },
        posterImgLoadingStrategy: {
            type: String,
            required: false,
            default: 'lazy'
        },
        bindVideoSrcImmediately: {
            type: Boolean,
            require: false,
            default: false
        },
        videoPreloadStrategy: {
            type: String,
            required: false,
            default: 'metadata'
        },
        disableControlsHidingWhenPlaying: {
            type: Boolean,
            default: false
        },
        persistControls: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        volumeOptionsOpen: false,
        volume: 0.3,
        lastVolume: 0.3,
        isPlaying: false,
        isPlayingStarted: false,
        duration: 0,
        currentTime: 0,
        videoSrc: null,
        isWaiting: true,
        showControls: false,
        timeout: null,
        isDraggingProgress: false,
        isDraggingVolume: false,
        autoplayIsInterrupt: false,
        currentSubString: ''
    }),
    computed: {
        ...mapGetters(['isMobile']),
        currentTimeFormatted () {
            return this.formatTime(this.currentTime)
        },
        durationFormatted () {
            return this.formatTime(this.duration)
        },
        progress () {
            return (this.currentTime / this.duration) * 100
        },
        isControls () {
            return this.persistControls || (this.hasControls && this.showControls && !(this.poster && !this.isPlayingStarted))
        },
        isPreviewAnimation () {
            return !this.isPlayingStarted && !this.autoplay && this.poster
        },
        videoSrcProp () {
            return this.bindVideoSrcImmediately ? this.videoUrl : this.videoSrc
        }
    },
    watch: {
        videoUrl (val) {
            if (!!val && val !== this.videoSrc) {
                this.isWaiting = true
                this.videoSrc = val
                this.$forceUpdate()
                this.isPlayingStarted = false
                this.autoplayIsInterrupt = false
                this.startAutoplay()
            }
        }
    },
    mounted () {
        this.updateVideoDetails()
        this.videoSrc = this.videoUrl
        this.$nuxt.$on('onVideoPlay', this.allVideosPause)
        window.addEventListener('mouseup', this.dragend)
        window.addEventListener('touchend', this.dragend)
        window.addEventListener('mousemove', this.mousemove)
        window.addEventListener('touchmove', this.mousemove)
        this.startAutoplay()
    },
    beforeDestroy () {
        window.removeEventListener('mouseup', this.dragend)
        window.removeEventListener('touchend', this.dragend)
        window.removeEventListener('mousemove', this.mousemove)
        window.removeEventListener('touchmove', this.mousemove)
    },
    methods: {
        startAutoplay () {
            if (this.autoplay && !this.isMobile && this.$refs.videoPlayer) {
                this.$refs.videoPlayer.muted = true
                this.$nextTick(() => {
                    try {
                        this.$refs.videoPlayer.play().catch(() => {})
                    } catch {}
                })
            }
        },
        formatTime (num) {
            let hours = Math.floor(num / 3600)
            let minutes = Math.floor((num % 3600) / 60)
            let seconds = Math.floor(num % 60)
            hours = hours < 10 ? '0' + hours : hours
            minutes = minutes < 10 ? '0' + minutes : minutes
            seconds = seconds < 10 ? '0' + seconds : seconds
            if (hours > 0) {
                return hours + ':' + minutes + ':' + seconds
            }
            return minutes + ':' + seconds
        },
        setVolumeByIconClick (value) {
            if (!this.isMobile) {
                this.volume = value
                this.$refs.videoPlayer.volume = value
            }
        },
        dragstart (target) {
            this.isDraggingProgress = target === 'progress'
            this.isDraggingVolume = target === 'volume'
        },
        mousemove (event) {
            let pageX = event.pageX
            let pageY = event.pageY
            if (event.touches && event.touches[0]) {
                pageX = event.touches[0].pageX
                pageY = event.touches[0].pageY
            }
            if (this.isDraggingProgress) {
                if (event.x !== 0 && event.y !== 0) {
                    const track = this.$refs.videoPlayerProgress
                    if (track) {
                        const leftMovement = pageX - track.getBoundingClientRect().left
                        let drag = 0
                        drag = leftMovement
                        if (leftMovement < 0) {
                            drag = 0
                        } else if (leftMovement > track.offsetWidth) {
                            drag = track.offsetWidth
                        }
                        this.currentTime = this.duration * (drag / track.offsetWidth)
                        this.$refs.videoPlayer.currentTime = this.currentTime
                    }
                }
            } else if (this.isDraggingVolume) {
                if (event.x !== 0 && event.y !== 0) {
                    const volume = this.$refs.videoVolumeTrack
                    const currentVolume = (volume.getBoundingClientRect().top + window.scrollY - pageY + volume.offsetHeight) / 100
                    if (currentVolume > 1) {
                        this.volume = currentVolume
                        this.$refs.videoPlayer.volume = 1
                    } else if (currentVolume < 0.1) {
                        this.volume = 0
                        this.$refs.videoPlayer.volume = 0
                    } else {
                        this.volume = currentVolume
                        this.$refs.videoPlayer.volume = currentVolume
                    }
                    this.lastVolume = this.volume
                }
            }
        },
        dragend () {
            this.isDraggingProgress = false
            this.isDraggingVolume = false
        },
        handleFullscreen () {
            if (this.$refs.videoPlayer.requestFullscreen) {
                this.$refs.videoPlayer.requestFullscreen()
            } else if (this.$refs.videoPlayer.mozRequestFullScreen) {
                this.$refs.videoPlayer.mozRequestFullScreen()
            } else if (this.$refs.videoPlayer.webkitRequestFullscreen) {
                this.$refs.videoPlayer.webkitRequestFullscreen()
            } else if (this.$refs.videoPlayer.msRequestFullscreen) {
                this.$refs.videoPlayer.msRequestFullscreen()
            } else if (this.$refs.videoPlayer.webkitEnterFullScreen) {
                this.$refs.videoPlayer.webkitEnterFullScreen()
            }
        },
        allVideosPause (videoSrc) {
            if (videoSrc !== this.videoSrc) {
                this.isPlaying = false
                this.isPlayingStarted = false
                this.$refs.videoPlayer?.pause()
                if (this.$refs.videoPlayer?.currentTime && !this.disableAutoPause) {
                    this.$refs.videoPlayer.currentTime = 0
                }

                if (this.autoplay && this.disableAutoPause) {
                    this.autoplayIsInterrupt = false
                    this.startAutoplay()
                    this.$emit('pause')
                }
            }
        },
        pause () {
            this.isPlaying = false
            this.isPlayingStarted = false
            this.$refs.videoPlayer?.pause()
            if (this.$refs.videoPlayer?.currentTime) {
                this.$refs.videoPlayer.currentTime = 0
            }
        },
        play () {
            if (this.$refs.videoPlayer) {
                this.isPlaying = true
                this.isPlayingStarted = true
                try {
                    const playPromise = this.$refs.videoPlayer.play()
                    playPromise.then(() => {
                        this.$nuxt.$emit('onVideoPlay', this.videoSrc)
                    }).catch(() => {})
                } catch {}
            }
        },
        canplay () {
            this.isWaiting = false
            this.$emit('canplay')
        },
        waiting () {
            this.isWaiting = true
            this.$emit('waiting')
        },
        toggleVideoPlay () {
            if (this.$refs?.videoPlayer?.paused || this.isPreviewAnimation) {
                this.isPlaying = true
                this.isPlayingStarted = true
                this.$nextTick(() => {
                    const playPromise = this.$refs.videoPlayer?.play()
                    if (playPromise) {
                        playPromise
                            .then(() => { this.$nuxt.$emit('onVideoPlay', this.videoSrc) })
                            .catch(() => {})
                    }
                })
            } else {
                // клик по воспроизводимому видео в autoplay режиме не ставит видео на паузу,
                // а включает звук и отключает autoplay
                if (this.persistControls) {
                    this.isPlayingStarted = true
                    this.autoplayIsInterrupt = true
                    this.$refs.videoPlayer.muted = false
                }

                if (!this.autoplayIsInterrupt && this.autoplay && !this.isMobile) {
                    if (this.isPlayFromStartAfterClick) {
                        this.$refs.videoPlayer.currentTime = 0
                        this.currentSubString = ''
                    }
                    this.isPlayingStarted = true
                    this.autoplayIsInterrupt = true
                    this.$refs.videoPlayer.muted = false
                    return
                }
                this.isPlaying = false
                this.$refs?.videoPlayer?.pause()
            }
        },
        handleVolumeClick (event) {
            const volume = this.$refs.videoVolumeTrack
            const currentVolume = (volume.getBoundingClientRect().top + window.scrollY - event.pageY + volume.offsetHeight) / 100
            if (currentVolume > 1) {
                this.volume = currentVolume
                this.$refs.videoPlayer.volume = 1
            } else if (currentVolume < 0.1) {
                this.volume = 0
                this.$refs.videoPlayer.volume = 0
            } else {
                this.volume = currentVolume
                this.$refs.videoPlayer.volume = currentVolume
            }
            this.lastVolume = this.volume
        },
        updateVideoDetails (e) {
            this.$emit('loadedmetadata', e)
            if (this.$refs.videoPlayer) {
                if (!Number.isNaN(this.$refs.videoPlayer.duration)) {
                    this.duration = this.$refs.videoPlayer.duration
                }
                this.currentTime = this.$refs.videoPlayer.currentTime
                if (this.$refs?.videoPlayer.paused) {
                    this.isPlaying = false
                    this.$refs.videoPlayer.pause()
                } else {
                    const playPromise = this.$refs.videoPlayer.play()
                    playPromise.then(() => {
                        this.isPlaying = true
                    }).catch(() => {})
                }

                // subtitles
                if (this.subtitlesSrc && this.$refs.videoPlayer.textTracks) {
                    const tracks = this.$refs.videoPlayer.textTracks[0]
                    if (tracks) {
                        tracks.mode = 'hidden'
                        const cues = tracks.cues

                        for (const i in cues) {
                            const cue = cues[i]
                            if (typeof cue === 'object') {
                                cue.onenter = () => this.subEnter(cue)
                                cue.onexit = () => this.subExit(cue)
                            }
                        }
                    }
                }
            }
        },
        subEnter (cue) {
            this.currentSubString = cue.text
        },
        subExit (cue) {
            if (+cue.id === cue?.track?.cues?.length) {
                this.currentSubString = ''
            }
        },
        handleProgressClick (event) {
            const currentTime = (this.duration * event.offsetX) / this.$refs.videoPlayerProgress.offsetWidth
            this.currentTime = currentTime
            this.$refs.videoPlayer.currentTime = currentTime
        },
        handleShowControls () {
            this.showControls = true
            if (this.timeout) {
                clearTimeout(this.timeout)
                this.timeout = null
            }
        },
        setTimeoutFunction () {
            this.timeout = setTimeout(() => {
                this.showControls = false
                this.volumeOptionsOpen = false
                this.timeout = null
            }, 1000)
        }
    }
}
