//
//
//
//
//
//

import { randomIntFromInterval } from '~/utils/helpers'

export default {
    name: 'CoVideoPlayerTranscription',
    props: {
        transcription: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        startIndex: 0,
        endIndex: 0,
        timer: null,
        computedTranscription: ''
    }),
    computed: {
        transcriptionByWords () {
            return this.transcription.split(' ')
        }
    },
    mounted () {
        this.startTranscription()
    },
    beforeDestroy () {
        clearTimeout(this.timer)
    },
    methods: {
        startTranscription () {
            if (this.transcriptionByWords.length) {
                this.startIndex = 0
                this.endIndex = 0
                this.computedTranscription = ''
                this.setTranscriptionTimer()
            }
        },
        setTranscriptionTimer () {
            this.timer = setTimeout(() => {
                this.startIndex = this.endIndex
                this.endIndex += randomIntFromInterval(5, 15)
                if (this.endIndex > this.transcriptionByWords.length) {
                    clearTimeout(this.timer)
                    this.startTranscription()
                    return
                }
                this.setTranscriptionTimer()
                this.computedTranscription = [...this.transcriptionByWords.slice(this.startIndex, this.endIndex)].join(' ')
            }, randomIntFromInterval(3000, 5000))
        }
    }
}
