import { render, staticRenderFns } from "./CoVideoPlayer.vue?vue&type=template&id=b151c0f4"
import script from "./CoVideoPlayer.vue?vue&type=script&lang=js"
export * from "./CoVideoPlayer.vue?vue&type=script&lang=js"
import style0 from "./CoVideoPlayer.vue?vue&type=style&index=0&id=b151c0f4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoVideoPlayerTranscription: require('/var/www/udimi-front-outside/releases/803/components/common/CoVideoPlayerTranscription.vue').default})
